module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-MT5H5NL","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MT5H5NL","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"admin-debuxing.000webhostapp.com/","protocol":"http","maxWidth":650},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
